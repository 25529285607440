import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Allcarditems from "../Allcarditems";
import { Dropdown, Pagination, Space, Spin } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { API_URL } from "../Config";

interface DropdownItem {
  key: string;
  label: string;
  onClick?: () => void;
  children?: DropdownItem[];
}

const items: DropdownItem[] = [
  {
    key: "1",
    label: "Sort by",
    children: [
      { key: "2", label: "Low To High" },
      { key: "3", label: "High To Low" },
    ],
  },
];

const Allmutton = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [sortedProducts, setSortedProducts] = useState<any[]>(products);
  const [sortOrder, setSortOrder] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalResult, setTotalResult] = useState<number>(0);
  const pageSize = 12;

  const getAllProduct = async (page: number) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/product?category=mutton&page=${page}&limit=${pageSize}&isDeleted=false`
      );
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
      setTotalResult(response.data.totalResults);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllProduct(currentPage);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [currentPage]);

  useEffect(() => {
    let sorted = [...products];
    if (sortOrder === "2") {
      sorted.sort((a, b) => a.price - b.price);
    } else if (sortOrder === "3") {
      sorted.sort((a, b) => b.price - a.price);
    }
    setSortedProducts(sorted);
  }, [products, sortOrder]);

  const handleSort = (key: string) => {
    setSortOrder(key);
  };

  return (
    <>
      <Helmet>
        <title>
          Buy Goat & Lamb Mutton Online - Fresh, Clean, & Halal | At Meat Market
        </title>
        <meta
          name="description"
          content="Order fresh, clean, and halal goat and lamb mutton online at Meat Market. Enjoy premium quality meat delivered to your doorstep, hassle-free!"
        />
        <meta
          name="keywords"
          content="fresh goat mutton, lamb meat delivery, goat meat online shopping, organic mutton, fresh halal meat, mutton home delivery, goat and lamb meat, halal meat market, order goat meat, lamb meat shop online, mutton delivery service, premium goat meat, fresh lamb mutton, goat and lamb delivery, buy halal meat online, tender goat mutton, fresh meat delivery, lamb mutton online shopping, halal goat meat delivery"
        />
      </Helmet>

      <section className="py-4">
        <Container>
          <p className="nav-all pb-3">
            <Link to="/">Home</Link> {">"} <span>Mutton</span>
          </p>
          <div className="d-flex justify-content-between align-items-center allitems-nav mb-3">
            <h2>
              Mutton <span>{totalResult} Varieties</span>
            </h2>
            <Dropdown
              menu={{
                items:
                  items[0].children?.map((item) => ({
                    key: item.key,
                    label: item.label,
                    onClick: () => handleSort(item.key),
                  })) || [],
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Sort By
                  <i className="fi fi-sr-settings-sliders"></i>
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="row">
            {sortedProducts.length ? (
              sortedProducts.map((product) => (
                <Allcarditems key={product._id} product={product} />
              ))
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <Spin size="large" className="custom-spin" />
              </div>
            )}
          </div>
          {/* Pagination Component */}
          <div className="d-flex justify-content-center mt-4">
            <Pagination
              current={currentPage}
              total={totalPages * pageSize}
              pageSize={pageSize}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </Container>
      </section>
    </>
  );
};

export default Allmutton;
