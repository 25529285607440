import { Avatar, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IoIosStar, IoMdCloseCircleOutline } from "react-icons/io";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_URL } from "../Config";
import { Link, useParams } from "react-router-dom";
import "./Orders.css";
import moment from "moment";
import { message } from "antd";
import { handlePostRating } from "../Providers/Api_Calls";
import { GoDotFill } from "react-icons/go";

// Define the structure of the order data
interface User {
  _id: string;
  name: string;
  email: string;
  phone: number;
}

interface LineItem {
  product: any;
  quantity: number;
  _id: string;
}

interface Order {
  _id: string;
  orderId: string;
  user: User;
  lineItems: LineItem[];
  createdAt: string;
  type: string;
  deliverySlot: string;
  address: string;
  paymentMethod: string;
  deliveryDate: string | null;
  paymentStatus: string;
  paymentAmount: number;
  status: string;
  deliveryInstructions: string;
  isCancelled: boolean;
  cancellationReason: string;
  cancellationDate: string;
  refundStatus: string;
  isDeleted: boolean;
  __v: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
};

const DeliveryDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState<Order | null>(null);

  const formatDate = (dateString: any) => {
    return moment(dateString).format("ddd, Do MMM");
  };

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const [openrating, setOpenrating] = React.useState(false);
  const [ratingData, setRatingData] = useState({
    product: "",
    user: "",
    rating: 0,
    comment: "",
  });

  const getOrderbyId = async () => {
    try {
      let fetchOrder = await axios.get(
        `https://api.meatmarket.live/api/order/${id}`
      );
      setData(fetchOrder.data.order);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpenRating = (data: any) => {
    setRatingData({
      ...ratingData,
      product: data.lineItems[0].product,
      user: data._id,
    });
    setOpenrating(true);
  };

  const handleCloseRating = () => setOpenrating(false);

  const handlepostRating = async () => {
    try {
      await handlePostRating(ratingData.product, ratingData);
      successmessage("Thanks for your rating");
      handleCloseRating();
    } catch (error) {
      errormessage("Something went wrong");
    }
  };

  const handleRatingChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | null
  ) => {
    setRatingData({
      ...ratingData,
      rating: newValue ?? 0,
    });
  };

  useEffect(() => {
    if (id) {
      getOrderbyId();
    }
  }, [id]);

  return (
    <>
      {contextHolder}
      <section className="my-3 pt-4">
        <Container>
          <p className="nav-all pb-3">
            <Link to="/">Home</Link> {">"} <Link to="/my-order">My Orders</Link>{" "}
            {">"} <span>Order Details</span>
          </p>
          <div
            className="row mx-0"
            style={{
              backgroundColor: "#fff",
              border: "1px solid #0D354F",
              borderRadius: "10px",
            }}
          >
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="p-2">
                <p className="mb-1" style={{ fontWeight: 700 }}>
                  Delivery Address
                </p>
                <p className="mb-1" style={{ fontWeight: 600 }}>
                  {data?.user?.name || "Name not available"}
                </p>
                <p className="mb-1" style={{ fontWeight: 400 }}>
                  {data?.address || "Address not available"}
                </p>
                <p className="mb-1">
                  <span className="pe-2" style={{ fontWeight: 700 }}>
                    Phone number
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    {data?.user?.phone || "Phone number not available"}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div
            className="row my-2 mx-0 sm-hide"
            style={{ backgroundColor: "#F4F4F4", cursor: "pointer" }}
          >
            <div className="col-lg-4 col-xl-4 col-sm-12 col-md-4 px-0">
              <div className="d-flex justify-content-start align-items-center sm-avatar">
                <div>
                  <Avatar
                    className="avatarOrder"
                    alt="Product Image"
                    src={
                      data?.lineItems[0]?.product?.image
                        ? `${API_URL}/images/${data.lineItems[0].product.image}`
                        : "/path/to/default/image.jpg"
                    }
                  />
                </div>
                <div style={{ marginLeft: "2rem" }}>
                  <p className="mb-0 order-itemname">
                    {data?.lineItems && data.lineItems.length > 0
                      ? data.lineItems
                          .map((val) =>
                            val.product?.name
                              ? val.product.name
                              : "Unknown product"
                          )
                          .join(", ")
                      : "No products available"}
                  </p>
                  <p className="mb-0">
                    {data?.lineItems && data.lineItems.length > 0
                      ? data.lineItems.map((val) => val.quantity).join(", ")
                      : "No quantity available"}{" "}
                    gm
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 amountHide-sm col-xl-4 col-sm-6 col-6 col-md-4 px-0 d-flex justify-content-center align-items-center">
              <div>
                <p className="mb-0" style={{ fontWeight: 600 }}>
                  ₹ {data?.paymentAmount.toFixed() || "Price not available"}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-sm-12 col-12 col-md-4 px-0 d-flex justify-content-end align-items-center">
              <div style={{ padding: "0px 12px" }}>
                <p
                  className="mb-2 d-flex justify-content-start align-items-center"
                  style={{ fontWeight: 700 }}
                >
                  <GoDotFill style={{ color: "#0D354F" }} />
                  {data?.deliveryDate
                    ? `Delivery On ${formatDate(data?.deliveryDate)}`
                    : "Delivery Date"}
                </p>
                <p
                  className="mb-2"
                  style={{ fontSize: "14px", fontWeight: 600 }}
                >
                  Your item has been delivered
                </p>
                <p
                  className="mb-0 d-flex justify-content-start align-items-center"
                  style={{
                    color: "#474747",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                  // onClick={() => handleOpenRating({ product: order.lineItems[0]._id })}
                  onClick={() => handleOpenRating(data)}
                >
                  <IoIosStar style={{ color: "#0D354F" }} />
                  Rate & Review Product
                </p>
              </div>
            </div>
          </div>

          {/* Modal */}

          <div>
            <Modal
              open={openrating}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="d-flex justify-content-between align-items-center box-title">
                  <div>
                    <h3>Rate & Review</h3>
                  </div>
                  <div>
                    <IoMdCloseCircleOutline
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={handleCloseRating}
                    />
                  </div>
                </div>
                <div className="rating-box p-3">
                  <h4>Rate This Product:</h4>
                  <Rating
                    name="simple-controlled"
                    onChange={handleRatingChange}
                  />
                  <h4>Review This Product:</h4>
                  <textarea
                    rows={4}
                    onChange={(e) =>
                      setRatingData({ ...ratingData, comment: e.target.value })
                    }
                  ></textarea>
                  <div className="text-center py-3">
                    <Button variant="contained" onClick={handlepostRating}>
                      Confirm
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </Container>
      </section>
    </>
  );
};

export default DeliveryDetails;
