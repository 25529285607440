import axios from "axios";
import { API_URL } from "../Config";

axios.defaults.withCredentials = true;

const getAuthToken = () => {
  return localStorage.getItem("authtoken");
};

const getHeaders = () => {
  const token = getAuthToken();
  // console.log(token,"token");
  return {
    "Content-Type": "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
};

//All Products

export const allProducts = async (
  endpoint: string,
  page: any
): Promise<any> => {
  try {
    const response = await axios.get(
      new URL(endpoint + "?" + "page=" + page, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
    throw error;
  }
};

// Login
export const loginUser = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

// SignUp
export const signupUser = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

// ForgetPassword
export const forgetpassUser = async (
  endpoint: string,
  data: any
): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

// BulkOrders
export const bulkOrders = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

// //Request Franchise
export const requestFranchise = async (
  endpoint: string,
  data: any
): Promise<any> => {
  const token = getAuthToken();
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//GetsingleProduct
export const getSingleProduct = async (
  endpoint: string,
  productId: any
): Promise<any> => {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//PostOrder
export const postOrder = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//GetMe
export const getMe = async (endpoint: string): Promise<any> => {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Getall orders
export const getAllorders = async (endpoint: string, id: any): Promise<any> => {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${id}`, API_URL).toString(),
      {
        headers: getHeaders(),
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Patch Address
export const patchAddress = async (
  endpoint: string,
  productId: any
): Promise<any> => {
  try {
    const response = await axios.patch(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Myorders
export const myOrders = async (
  endpoint: string,
  productId: any
): Promise<any> => {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//UpdateUser
export const updateuser = async (
  endpoint: string,
  productId: any,
  data: any
): Promise<any> => {
  try {
    const response = await axios.patch(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//cancelOrders
export const cancelOrders = async (
  endpoint: string,
  productId: any,
  data: any
): Promise<any> => {
  try {
    const response = await axios.patch(
      new URL(endpoint + `/${productId}`, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//PostRating
export const handlePostRating = async (
  productId: string,
  ratingData: any
): Promise<any> => {
  try {
    const url = new URL(`api/review?product=${productId}`, API_URL).toString();
    const response = await axios.post(url, ratingData, {
      headers: getHeaders(),
    });

    return response.data;
  } catch (error) {
    console.error("Failed to post rating:", error);
    throw error;
  }
};

//GetRating
export const getReviews = async (endpoint: string): Promise<any> => {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Transition
export const amountTransfer = async (
  endpoint: string,
  data: any
): Promise<any> => {
  try {
    const response = await axios.post(
      new URL(endpoint, API_URL).toString(),
      data,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

//Fetch Banner
export const fetchBannerData = async (endpoint: string): Promise<any> => {
  try {
    const response = await axios.get(new URL(endpoint, API_URL).toString(), {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};

// getbySlug
export const getSlugProduct = async (
  endpoint: string,
  slug: any
): Promise<any> => {
  try {
    const response = await axios.get(
      new URL(endpoint + `/${slug}`, API_URL).toString(),
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to post data:", error);
    throw error;
  }
};
