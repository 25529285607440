import { Button } from "@mui/material";
import React, { useState } from "react";
import { Card, message } from "antd";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../src/Config";
import { useMediaQuery } from "react-responsive";

interface Product {
  _id: string;
  productId: number;
  name: string;
  image: string;
  category: string;
  price: number;
  minWeightInGrams: number;
  rating: number;
  description: string;
  deliveryInfo: string;
  nutritionalInfo: string;
  discountInPercent: number;
  isDeleted: boolean;
  slug: string;
}

interface AllcarditemsProps {
  product: Product;
}

interface LineItem {
  product: string;
  quantity: number;
  discountInPercent: number;
  productPrice: number;
  discountedPrice: number;
}

interface OrderData {
  orderId: number;
  lineItems: LineItem[];
  type: string;
  deliverySlot: string;
  address: string;
  paymentMethod: string;
  paymentStatus: string;
  status: string;
  deliveryInstructions: string;
  isCancelled: boolean;
  cancellationReason: string | null;
  refundStatus: string;
  paymentAmount: number;
  productName: string;
  discountInPercent: number;
  originalPrice: number;
  originalQuantity: number;
  totalAmount: number;
}

const CardItem: React.FC<AllcarditemsProps> = ({ product }) => {
  const [weight, setWeight] = useState(product.minWeightInGrams);

  const handleIncrement = () => {
    // setWeight((prevWeight) => prevWeight + 100);
    setWeight((prevWeight) => {
      if (prevWeight < 1500) {
        return prevWeight + 100;
      }
      return prevWeight;
    });
  };

  const handleDecrement = () => {
    if (weight > product.minWeightInGrams) {
      setWeight((prevWeight) => prevWeight - 100);
    }
  };

  const navigate = useNavigate();
  const handleNavProductDetail = (slug: string) => {
    navigate(`/${slug}`);
  };

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const handleAddToCart = () => {
    let cart = JSON.parse(localStorage.getItem("cart") || "[]");

    const totalPrice = (weight / product.minWeightInGrams) * product.price;
    const discountAmount = totalPrice * (product.discountInPercent / 100);
    const finalPrice = totalPrice - discountAmount;

    const orderData: OrderData = {
      orderId: Date.now(),
      lineItems: [
        {
          product: product._id,
          quantity: weight,
          productPrice: product.price,
          discountInPercent: product.discountInPercent,
          discountedPrice:
            product.price - (product.price * product.discountInPercent) / 100,
        },
      ],
      type: "normal",
      deliverySlot: "",
      address: "",
      paymentMethod: "",
      paymentStatus: "",
      status: "",
      deliveryInstructions: "",
      isCancelled: false,
      cancellationReason: null,
      refundStatus: "",
      paymentAmount: finalPrice,
      productName: product.name,
      discountInPercent: product.discountInPercent,
      originalPrice: product.price,
      originalQuantity: product.minWeightInGrams,
      totalAmount: totalPrice,
    };

    cart.push(orderData);

    localStorage.setItem("cart", JSON.stringify(cart));
    successmessage("Item added to cart!");
    window.dispatchEvent(new Event("storage"));
  };

  const isSmallDevice = useMediaQuery({ query: "(max-width: 767px)" });

  const totalPrice = (weight / product.minWeightInGrams) * product.price;
  const discountAmount = totalPrice * (product.discountInPercent / 100);
  const finalPrice = totalPrice - discountAmount;

  return (
    <>
      {contextHolder}
      <div className="col-lg-3 col-xxl-3 col-xl-3 item-card col-sm-6 col-6 col-md-4 mt-2 allitems">
        <div className="position-relative mobile-card">
          <Card
            style={{
              width: "100%",
              overflow: "hidden",
              borderBottomRightRadius: isSmallDevice ? "0px" : "60px",
              cursor: "pointer",
            }}
            cover={
              <img
                alt="img"
                className="item-img"
                src={`${API_URL}/images/${product.image}`}
                loading="lazy"
              />
            }
            onClick={() => handleNavProductDetail(product.slug)}
          />
          <div className="d-flex justify-content-between  item-head">
            <div>
              <div className="ratingStar m-2">
                <span
                  className="d-flex justify-content-center align-items-center"
                  style={{ fontSize: "15px", fontWeight: 600, color: "#000" }}
                >
                  <FaStar style={{ marginRight: "5px", color: "#D11243" }} />
                  {product.rating}.0
                </span>
              </div>
            </div>
          </div>
          <div className="item-foot p-2">
            <div
              className="d-flex flex-wrap justify-content-between align-items-center pb-2 card-dec1"
              style={{ width: "100%" }}
            >
              <div
                className=""
                style={{ width: `${isSmallDevice ? "auto" : "60%"}` }}
              >
                <h6 className="card-item-head truncated-text position-relative">
                  {product.name}
                </h6>
                {/* <span className="piece" style={{fontSize:"12px",fontWeight:600}}>1-3 Pieces</span> */}
              </div>
              <div
                className="increDec"
                style={{ width: `${isSmallDevice ? "auto" : "40%"}` }}
              >
                <span className="minus" onClick={handleDecrement}>
                  -
                </span>
                <span className="inputQuantity">{weight}g</span>
                <span className="plus" onClick={handleIncrement}>
                  +
                </span>
              </div>
            </div>
            <div className="d-flex flex-wrap align-items-center card-dec1">
              <div>
                <h4 className="card-price sm-hide">
                  {product.discountInPercent > 0 ? (
                    <div className="amount">
                      <p className="discount-amount mb-0">
                        ₹{finalPrice.toFixed()}
                      </p>
                    </div>
                  ) : (
                    <p className="mb-0">₹{totalPrice.toFixed()}</p>
                  )}
                </h4>
                <h4 className="card-price lg-hide">₹{finalPrice.toFixed()}</h4>

                {product.discountInPercent > 0 && (
                  <span
                    className="discount-amount mb-0 lg-amount"
                    style={{ textDecoration: "line-through" }}
                  >
                    ₹{totalPrice.toFixed()}
                  </span>
                )}
              </div>
              <div className="mb-4 d-none d-sm-block">
                {product.discountInPercent > 0 && (
                  <span className="discount">
                    {product.discountInPercent}% Off
                  </span>
                )}
              </div>
              <div className="mb-4 d-block d-sm-none">
                {product.discountInPercent > 0 && (
                  <span className="discount ">
                    {product.discountInPercent}% Off
                  </span>
                )}
              </div>
              <div>
                <Button
                  variant="contained"
                  className="cartBtn"
                  onClick={handleAddToCart}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardItem;
