import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../MainFiles.tsx/Home";
import ChickenM from "../MainFiles.tsx/ChickenM";
import MuttonM from "../MainFiles.tsx/MuttonM";
import MyOrdersM from "../MainFiles.tsx/MyOrdersM";
import DeliveryDetails from "../MainFiles.tsx/OrderInfoDetails";
import ProductDetails from "../MainFiles.tsx/ProductDetails";
import Search from "../Home/Search";
import Badrequest from "../MainFiles.tsx/Badrequest";
import CancelOrders from "../MainFiles.tsx/CancelOrders";
import OrderInfoDetails from "../MainFiles.tsx/OrderInfoDetails";
import DeliveredDEtails from "../MainFiles.tsx/DeliveredDEtails";
import Contactus from "../MainFiles.tsx/Contactus";
import PrivacyPloicy from "../MainFiles.tsx/PrivacyPloicy";
import RefundPolicy from "../MainFiles.tsx/RefundPolicy";
import TermsCondition from "../MainFiles.tsx/TermsCondition";
import Aboutus from "../MainFiles.tsx/Aboutus";
import Resetpass from "../Home/Resetpass";
import BlogsMain from "../blogs/BlogsMain";
import BlogPage from "../blogs/BlogPage";
const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/chicken" element={<ChickenM />} />
          <Route path="/mutton" element={<MuttonM />} />
          <Route path="/blogs" element={<BlogsMain />} />
          <Route path="/blogs/:slug" element={<BlogPage />} />
          <Route path="/my-order" element={<MyOrdersM />} />
          <Route path="/delivery-details" element={<DeliveryDetails />} />
          <Route path="/cancelled-order/:id" element={<CancelOrders />} />
          <Route path="/:slug" element={<ProductDetails />} />
          <Route path="/order-detail/:id" element={<OrderInfoDetails />} />
          <Route path="/delivered-detail/:id" element={<DeliveredDEtails />} />
          <Route path="/search" element={<Search />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/privacy-policy" element={<PrivacyPloicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/termsandconditions" element={<TermsCondition />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="*" element={<Badrequest />} />
          <Route path="/reset-password/:id" element={<Resetpass />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
