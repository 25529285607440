import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Allcarditems from "../Allcarditems";
import { allProducts } from "../Providers/Api_Calls";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import { Container } from "react-bootstrap";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";

const Search = () => {
  const [products, setProducts] = useState<any[]>([]);
  const location = useLocation();

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("allProduct"), 1);
      const totalPages = firstResponse.totalPages;
      let allfetchProducts: any[] = [...firstResponse.products];

      // Fetch the remaining pages
      for (let page = 2; page <= totalPages; page++) {
        const response = await allProducts(ApiEndPoints("allProduct"), page);
        allfetchProducts = [...allfetchProducts, ...response.products];
      }
    
      setProducts(allfetchProducts);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  const queryParams = new URLSearchParams(location.search);
  const searchValue = queryParams.get("query") || "";

  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      product.description.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <>
      <Header />
      <section className="pb-5" style={{ marginTop: "7rem" }}>
        <Container>
          <h2>Search result :</h2>
          <div className="row">
            {filteredProducts.length ? (
              filteredProducts.map((product) => (
                <Allcarditems key={product._id} product={product} />
              ))
            ) : (
              <h2 className="text-center">NO items found !</h2>
            )}
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Search;
