import { Avatar, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_URL } from "../Config";
import { Link, useParams } from "react-router-dom";
import "./Orders.css";
import moment from "moment";
import { message } from "antd";
import { cancelOrders } from "../Providers/Api_Calls";
import { ApiEndPoints } from "../Providers/Api_endpoints";

// Define the structure of the order data
interface User {
  _id: string;
  name: string;
  email: string;
  phone: number;
}

interface LineItem {
  product: any;
  quantity: number;
  _id: string;
}

interface Order {
  _id: string;
  orderId: string;
  user: User;
  lineItems: LineItem[];
  date: string;
  type: string;
  deliverySlot: string;
  address: string;
  paymentMethod: string;
  deliveryDate: string | null;
  paymentStatus: string;
  paymentAmount: number;
  status: string;
  deliveryInstructions: string;
  isCancelled: boolean;
  cancellationReason: string;
  cancellationDate: string;
  refundStatus: string;
  isDeleted: boolean;
  __v: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
};

const OrderDetails = () => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState<Order | null>(null);
  const [canceldata, setcanceldata] = useState({
    cancellationReason: "",
    isCancelled: true,
    cancellationDate: new Date(),
  });

  const formatDate = (dateString: any) => {
    return moment(dateString).format("ddd, Do MMM");
  };

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content:any) => {
    messageApi.open({
      type: 'success',
      content: content,
      style: {
        fontSize: "16px", 
        fontWeight: "bold", 
      },
    });
  };

  const errormessage = (content:any) => {
    messageApi.open({
      type: 'error',
      content: content,
      style: {
        fontSize: "16px", 
        fontWeight: "bold", 
      },
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getOrderbyId = async () => {
    try {
      let fetchOrder = await axios.get(`https://api.meatmarket.live/api/order/${id}`);
      setData(fetchOrder.data.order);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCancelitem = async () => {
    try {
      const updatedData = await cancelOrders(
        ApiEndPoints("cancelproduct"),
        id,
        canceldata
      );
      successmessage("Cancellation success")
      setOpen(false);
      setcanceldata({
        cancellationReason: "",
        isCancelled: true,
        cancellationDate: new Date(),
      });
    } catch (err) {
      console.log(err);
      errormessage(err)
    }
  };

  useEffect(() => {
    if (id) {
      getOrderbyId();
    }
  }, [id]);

  return (
    <>
    {contextHolder}
      <section className="my-3 pt-4">
        <Container>
          <p className="nav-all pb-3">
            <Link to="/">Home</Link> {">"} <Link to="/my-order">My Orders</Link> {">"} <span>Order Details</span>
          </p>
          <div
            className="row mx-0"
            style={{
              backgroundColor: "#fff",
              border: "1px solid #0D354F",
              borderRadius: "10px",
            }}
          >
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="p-2">
                <p className="mb-1" style={{ fontWeight: 700 }}>
                  Delivery Address
                </p>
                <p className="mb-1" style={{ fontWeight: 600 }}>
                  {data?.user?.name || "Name not available"}
                </p>
                <p className="mb-1" style={{ fontWeight: 400 }}>
                  {data?.address || "Address not available"}
                </p>
                <p className="mb-1">
                  <span className="pe-2" style={{ fontWeight: 700 }}>
                    Phone number
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    {data?.user?.phone || "Phone number not available"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            className="row mt-4 mx-0 "
            style={{ backgroundColor: "#F4F4F4", overflow: "hidden" }}
          >
            <div
              key={data?._id || "unknown"}
              className="row mx-0 my-2"
              style={{ backgroundColor: "#F4F4F4", cursor: "pointer" }}
            >
              <div className="col-lg-4 col-xl-4 col-sm-12 col-md-12 px-0">
                <div className="d-flex justify-content-start align-items-center">
                  <div>
                    <Avatar
                      className="avatarOrder"
                      alt="Product Image"
                      src={
                        data?.lineItems[0]?.product?.image
                          ? `${API_URL}/images/${data.lineItems[0].product.image}`
                          : "/path/to/default/image.jpg"
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "2rem" }}>
                    <p className="mb-0 order-itemname">
                      {data?.lineItems && data.lineItems.length > 0
                        ? data.lineItems
                            .map((val) =>
                              val.product?.name ? val.product.name : "Unknown product"
                            )
                            .join(", ")
                        : "No products available"}
                    </p>
                    <p className="mb-0" style={{ color: "#454545" }}>
                      {data?.lineItems && data.lineItems.length > 0
                        ? data.lineItems.map((val) => val.quantity).join(", ")
                        : "No quantity available"}{" "}
                      gm
                    </p>
                    <p className="mb-0" style={{ fontWeight: 600 }}>
                      ₹ {data?.paymentAmount.toFixed() || "Price not available"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-xl-8 col-sm-12 col-md-12 d-flex justify-content-center align-items-center">
                <div className="Wm9vLv sm-align">
                  {/* Status Indicators */}
                  <div className="q4l-J2" style={{ width: "33.333%" }}>
                    <div className="YrA9Sn">
                      <div
                        className={
                          data?.status === "ordered" || data?.status === "pending"
                            ? "SfJXc8O"
                            : "SfJXc8O-incomplete"
                        }
                      >
                        <span>Order Confirmed</span>
                      </div>
                      <div className="GS3O1E T7AO1y sDEgvV"></div>
                      <div
                        className={
                          data?.status === "out for delivery" ? "_9MhawTW" : "_9MhawT"
                        }
                      >
                        <div
                          className={
                            data?.status.toLowerCase() === "outfordelivery"
                              ? "_9XeNR9"
                              : "_9XeNR9-incomplete"
                          }
                        ></div>
                      </div>
                      <div className="SfJXc8">
                        <span>{formatDate(data?.date)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="q4l-J2" style={{ width: "33.333%" }}>
                    <div className="YrA9Sn">
                      <div
                        className={
                          data?.status.toLowerCase().trim() === "outfordelivery"
                            ? "SfJXc8O"
                            : "SfJXc8O-incomplete"
                        }
                      >
                        <span>Out For Delivery</span>
                      </div>
                      <div
                        className={`GS3O1E  sDEgvV ${
                          data?.status === "out for delivery" ? "delivery" : "T7AO1y-incomplete"
                        }`}
                      ></div>
                      <div className="_9MhawT">
                        <div
                          className={
                            data?.status.toLowerCase().trim() === "outfordelivery"
                              ? "_9XeNR9"
                              : "_9XeNR9-incomplete"
                          }
                        ></div>
                      </div>
                      <div className="SfJXc8"></div>
                    </div>
                  </div>
                  <div className="q4l-J2" style={{ width: "33.333%" }}>
                    <div className="YrA9Sn">
                      <div
                        className={
                          data?.status.toLowerCase().trim() === "completed"
                            ? "SfJXc8O"
                            : "SfJXc8O-incomplete"
                        }
                      >
                        <span>Delivery</span>
                      </div>
                      <div
                        className={`GS3O1E  sDEgvV ${
                          data?.status === "completed" ? "delivery" : "T7AO1y-incomplete"
                        }`}
                      ></div>
                      <div className="SfJXc8-incomplete">
                      {formatDate(data?.deliveryDate)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg-cancel">
                  <a
                    className="cancel-Order"
                    onClick={() => handleOpen()}
                  >
                    <i className="fi fi-rr-circle-xmark d-flex pe-2" />
                    <span style={{ paddingBottom: "4px" }}>cancel</span>
                  </a>
                </div>
                <div className="sm-cancel">
                  <a
                    className="cancel-Order justify-content-end"
                    onClick={() => handleOpen()}
                  >
                    <i className="fi fi-rr-circle-xmark d-flex pe-2" />
                    <span style={{ paddingBottom: "4px" }}>cancel</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="d-flex justify-content-between align-items-center box-title">
                    <div>
                      <h3>Cancellation</h3>
                    </div>
                    <div>
                      <IoMdCloseCircleOutline
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={handleClose}
                      />
                    </div>
                  </div>
                  <div className="p-3">
                    <div className="row cancel-box">
                      <div className="col-lg-5">
                        <div>
                          <h4>Reason For Cancellation:</h4>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div>
                          <textarea
                            rows={3}
                            onChange={(e) =>
                              setcanceldata({
                                ...canceldata,
                                cancellationReason: e.target.value,
                              })
                            }
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="text-center py-2 confrim-btn">
                      <Button variant="contained" onClick={handleCancelitem}>
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
        </Container>
      </section>
    </>
  );
};

export default OrderDetails;
