import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import { bulkOrders } from "../Providers/Api_Calls";
import { Checkbox, message } from "antd";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
  height: "90vh",
  overflowY: "scroll",
};

interface FranciesProps {
  open: boolean;
  closeModal: () => void;
}

const BulkOrders: React.FC<FranciesProps> = ({ open, closeModal }) => {
  const [isprogress, setisprogress] = useState(false);
  const [bulkData, setBulkData] = useState({
    chickenWeight: "",
    muttonWeight: "",
    date: "",
    deliveryDate: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBulkData({
      ...bulkData,
      [name]: value,
    });
  };

  // const handleSelectchicken = () => {
  //   setIsChicken(true);
  //   setIsMutton(false);
  //   setBulkData({ ...bulkData, product: "chicken" });
  // };
  // const handleSelectmutton = () => {
  //   setIsMutton(true);
  //   setIsChicken(false);
  //   setBulkData({ ...bulkData, product: "mutton" });
  // };

  const handleBulkOrders = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      (bulkData.chickenWeight === "" && bulkData.muttonWeight === "") ||
      bulkData.deliveryDate === "" ||
      bulkData.name === "" ||
      bulkData.phone === "" ||
      bulkData.email === "" ||
      bulkData.address === "" ||
      bulkData.pinCode === "" ||
      bulkData.state === "" ||
      bulkData.city === ""
    ) {
      errormessage("Please fill all fields!");
    } else if (
      (bulkData.chickenWeight === "" ||
        parseFloat(bulkData.chickenWeight) < 5) &&
      (bulkData.muttonWeight === "" || parseFloat(bulkData.muttonWeight) < 5)
    ) {
      errormessage("Order must be above 5kg!");
    } else {
      setisprogress(true);
      const data = {
        muttonWeight: bulkData.muttonWeight,
        chickenWeight: bulkData.muttonWeight,
        date: "",
        deliveryDate: bulkData.deliveryDate,
        name: bulkData.name,
        phone: Number(bulkData.phone),
        email: bulkData.email,
        address: `${bulkData.address}, ${bulkData.city}, ${bulkData.state}, ${bulkData.pinCode}`,
      };

      try {
        const result = await bulkOrders(ApiEndPoints("bulkOrders"), data);
        successmessage("Order request send successfully");
        setisprogress(false);
        closeModal();
        setBulkData({
          chickenWeight: "",
          muttonWeight: "",
          date: "",
          deliveryDate: "",
          name: "",
          phone: "",
          email: "",
          address: "",
          city: "",
          state: "",
          pinCode: "",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      {contextHolder}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className="d-flex justify-content-between align-items-center box-title">
              <div>
                <h3>Bulk Order Request</h3>
              </div>
              <div>
                <IoMdCloseCircleOutline
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={closeModal}
                />
              </div>
            </div>
            <form className="login" onSubmit={handleBulkOrders}>
              {/* <div>
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div className="py-1">
                    <Button
                      variant="contained"
                      id={`${isChicken ? "sect-chickActive" : "sect-chick"}`}
                      onClick={handleSelectchicken}
                    >
                      Chicken
                    </Button>
                  </div>
                  <div className="py-1">
                    <input
                      name="quantityInKg"
                      onChange={handleInputChange}
                      placeholder="Eg: 10 kg"
                      className="sect-input"
                    />
                  </div>
                  <div className="py-1">
                    <Button
                      variant="contained"
                      id={`${isMutton ? "sect-chickActive" : "sect-chick"}`}
                      onClick={handleSelectmutton}
                    >
                      Mutton
                    </Button>
                  </div>
                  <div className="py-1">
                    <input
                      name="quantityInKg"
                      onChange={handleInputChange}
                      placeholder="Eg: 10 kg"
                      className="sect-input"
                    />
                  </div>
                </div>
              </div> */}
              <label className="pb-3">
                <span style={{ color: "red" }}>*</span>Only order above 5Kg
              </label>
              <br />
              <div className="d-flex ">
                <div>
                  <Checkbox>Chicken</Checkbox>
                </div>
                <div className="py-1 me-2">
                  <input
                    name="chickenWeight"
                    onChange={handleInputChange}
                    placeholder="Eg: 10"
                    className="sect-input p-2"
                  />
                </div>
                <div>
                  <Checkbox>Mutton</Checkbox>
                </div>
                <div className="py-1">
                  <input
                    name="muttonWeight"
                    onChange={handleInputChange}
                    placeholder="Eg: 10"
                    className="sect-input p-2"
                  />
                </div>
              </div>
              <label>
                Name <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                name="name"
                value={bulkData.name}
                onChange={handleInputChange}
              />
              <br />
              <label>
                Delivery Date <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                name="deliveryDate"
                value={bulkData.deliveryDate}
                onChange={handleInputChange}
                placeholder="DD/MM/YYYY"
              />
              <br />
              <label>
                Email <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                name="email"
                value={bulkData.email}
                onChange={handleInputChange}
              />
              <br />
              <label>
                Phone Number <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                name="phone"
                value={bulkData.phone}
                onChange={handleInputChange}
              />
              <br />
              <label>
                Address <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                name="address"
                value={bulkData.address}
                onChange={handleInputChange}
              />
              <div className="d-flex justify-content-between">
                <div>
                  <label>
                    City <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <input
                    name="city"
                    value={bulkData.city}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mx-3">
                  <label>
                    State <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <input
                    name="state"
                    value={bulkData.state}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label>
                    Pin code <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <input
                    name="pinCode"
                    value={bulkData.pinCode}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="text-center py-4">
                {!isprogress ? (
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                ) : (
                  <h4>Sending...</h4>
                )}
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default BulkOrders;
