import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Recipe = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    autoplay: false,
  };

  return (
    <>
      <section className="py-4" style={{ overflow: "hidden" }}>
        <Container>
          <div className="slider-container">
            <Slider {...settings}>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <img
                      className="img-fluid briyani-img "
                      src="/assets/chickenbriyani.png"
                      loading="lazy"
                      alt="img"
                    />
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                      {/* <FaPlayCircle className="playicon"/> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Hyderabadi Biryani</h2>
                    <p>
                      Hyderabadi Biryani is a flavorful and aromatic dish
                      originating from Hyderabad, India. It is known for its
                      unique taste, rich spices, and layered rice and meat.
                      Here’s a detailed recipe for making traditional Hyderabadi
                      Biryani.
                    </p>
                    <h3>Ingredients:</h3>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <div>
                          <h6>For the Rice</h6>
                          <ul>
                            <li>2 cups Basmati rice</li>
                            <li>4 cups water</li>
                            <li>2-3 green cardamom pods</li>
                            <li>4 cloves</li>
                            <li>1-inch cinnamon stick</li>
                            <li>1 bay leaf</li>
                            <li>Salt to taste</li>
                          </ul>
                          <h6>For the Rice</h6>
                          <ul>
                            <li>1/4 cup ghee (clarified butter) or oil</li>
                            <li>1 large onion, thinly sliced</li>
                            <li>
                              A pinch of saffron soaked in 1/4 cup warm milk
                            </li>
                            <li>2 tbsp rose water or kewra water (optional)</li>
                            <li>Fresh coriander and mint leaves for garnish</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div>
                          <h6>For the Marinated Meat</h6>
                          <ul>
                            <li>1 kg chicken/mutton, cut into pieces</li>
                            <li>1 cup yogurt</li>
                            <li>2 tbsp ginger-garlic paste</li>
                            <li>1 tsp turmeric powder</li>
                            <li>1 tbsp red chili powder</li>
                            <li>1 tbsp garam masala powder</li>
                            <li>2-3 green chilies, slit</li>
                            <li>1/2 cup fried onions</li>
                            <li>1/4 cup fresh coriander leaves, chopped</li>
                            <li>1/4 cup fresh mint leaves, chopped</li>
                            <li>2 tbsp lemon juice</li>
                            <li>Salt to taste</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <img
                      className="img-fluid briyani-img"
                      src="/assets/muttonbriyani.png"
                      loading="lazy"
                      alt="img"
                    />
                    <div className="play-icon">
                      <i className="fi fi-ss-sheep playicon"></i>
                      {/* <FaPlayCircle className="playicon"/> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Djiz-biz</h2>
                    <p>
                      Djiz-biz is a traditional Azerbaijani dish made from lamb
                      offal, including liver, heart, lungs, spleen, kidneys, and
                      fat. It's a rich and hearty meal that's packed with
                      flavor. Here's a detailed recipe to prepare Djiz-biz at
                      home.
                    </p>
                    <h3>Ingredients:</h3>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <div>
                          <ul>
                            <li>
                              2 lbs mutton lamb (bone-in or boneless, cut into
                              pieces)
                            </li>
                            <li>3 tablespoons olive oil</li>
                            <li>
                              2 tablespoons Djiz spice mix (or a mix of paprika,
                              garlic powder, cayenne pepper, cumin, and salt)
                            </li>
                            <li>1 tablespoon lemon juice</li>
                            <li>1 teaspoon black pepper</li>
                            <li>4 cloves garlic (minced)</li>
                            <li>1 onion (sliced)</li>
                            <li>
                              Fresh herbs (like cilantro or parsley) for garnish
                            </li>
                          </ul>
                          <h3>Instructions:</h3>
                          <ul>
                            <li>
                              Marinate the Mutton: In a large bowl, combine the
                              olive oil, Djiz spice mix, lemon juice, black
                              pepper, and minced garlic. Add the mutton pieces,
                              ensuring they are well-coated in the marinade.
                              Cover and let marinate for at least 1 hour (or
                              overnight for more flavor).
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div>
                          <ul>
                            <li>
                              Sauté the Onion: In a large pot or skillet, heat a
                              little olive oil over medium heat. Add the sliced
                              onion and sauté until soft and translucent.
                            </li>
                            <li>
                              Brown the Mutton: Add the marinated mutton to the
                              pot and cook until browned on all sides (about 5-7
                              minutes).
                            </li>
                            <li>
                              Simmer: Add a cup of water or broth to the pot,
                              cover, and let it simmer on low heat for about 1.5
                              to 2 hours or until the mutton is tender. Stir
                              occasionally and add more water if necessary to
                              prevent sticking.
                            </li>
                            <li>
                              Finish and Serve: Once the mutton is tender,
                              remove from heat. Garnish with fresh herbs before
                              serving.
                            </li>
                            <li>
                              Enjoy: Serve the Djiz Biz mutton lamb with rice,
                              flatbread, or your favorite sides.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <img
                      className="img-fluid briyani-img"
                      src="/assets/image.png"
                      loading="lazy"
                      alt="img"
                    />
                    <div className="play-icon">
                      <i className="fi fi-ss-sheep playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Mutton Gee Roast</h2>
                    <p>
                      Mutton Ghee Roast is a rich and flavorful dish that
                      originates from the coastal regions of Karnataka, India,
                      particularly from the city of Mangalore. This dish is
                      known for its deep, spicy flavors and the rich aroma of
                      ghee (clarified butter), which is a key ingredient.
                    </p>
                    <h3>Ingredients:</h3>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>Add 2 Tbsp Clarified Butter/Ghee </li>
                          <li>500gms Mutton</li>
                          <li>Roast this for a few minutes. Then add,</li>
                          <li>1 Tbsp Ginger Garlic Paste</li>
                          <li>1/4 Tsp Turmeric Powder</li>
                          <li>1 Tsp Salt</li>
                          <li>1½ Cup Water</li>
                          <li>
                            Close the lid and pressure cook the mutton till its
                            cooked.
                          </li>
                          <li>
                            Meanwhile, on a pan, Roast 5 Dried Kashmiri Red
                            Chillies and remove.
                          </li>
                          <li>Then on the same pan, Add</li>
                          <li>2 Tbsp Coriander Seeds</li>
                          <li>1/2 Tbsp Cumin Seeds</li>
                          <li>1/2 Tsp PepperCorns</li>
                          <li>1 Tsp Fennel Seeds</li>
                          <li>1 Pinch Fenugreek Seeds</li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>1 Star Anise</li>
                          <li>3 Cloves</li>
                          <li>1 Piece Cinnamon</li>
                          <li>4-5 Dried Red Chillies</li>
                          <li>5-6 Garlic Cloves</li>
                          <li>
                            Roast and add to a grinder along with 1 Small Piece
                            of Tamarind
                          </li>
                          <li>1/4 Cup Curd</li>
                          <li>Water</li>
                          <li>Blend into a paste.</li>
                          <li>
                            Now in a vessel,Add 2 Tbsp Clarified Butter and the
                            red paste we just made.
                          </li>
                          <li>Along with, 2-3 Tbsp Water.</li>
                          <li>
                            Cook this till oil separates then add the cooked
                            mutton pieces along with 1/2 Tsp Salt
                          </li>
                          <li>10-12 Curry Leaves</li>
                          <li>Mix well and cover and cook for 5-10 minutes.</li>
                          <li>
                            Mutton Ghee Roast is ready. Serve with Dosa or
                            Paratha
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <img
                      className="img-fluid briyani-img"
                      src="/assets/soup.png"
                      loading="lazy"
                      alt="img"
                    />
                    <div className="play-icon">
                      <i className="fi fi-ss-sheep playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Goat Spleen Soup</h2>
                    <p>
                      Goat Spleen Soup is a traditional dish found in various
                      cuisines, particularly in regions where goat meat is a
                      common staple. Known for its rich, hearty flavor, this
                      soup is often valued not only for its taste but also for
                      its nutritional benefits.
                    </p>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h3>Ingredients:</h3>
                        <ul>
                          <li>
                            1 goat spleen, cleaned and chopped into pieces.
                          </li>
                          <li>1 large onion, finely chopped.</li>
                          <li>2 tomatoes, chopped.</li>
                          <li>4 cloves garlic, minced.</li>
                          <li>1-inch piece ginger, minced.</li>
                          <li>2 green chilies, slit.</li>
                          <li>1 tsp cumin seeds.</li>
                          <li>1 tsp coriander powder</li>
                          <li>1/2 tsp turmeric powder</li>
                          <li>1/2 tsp red chili powder (adjust to taste)</li>
                          <li>1 tsp garam masala</li>
                          <li>1 bay leaf</li>
                          <li>4-5 black peppercorns</li>
                          <li>1 cinnamon stick</li>
                          <li>2-3 cloves</li>
                          <li>Salt to taste</li>
                          <li>2 tbsp cooking oil or ghee</li>
                          <li>Fresh coriander leaves for garnish</li>
                          <li>4 cups water or broth</li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <h3>Instructions:</h3>
                        <p style={{ fontWeight: 600 }}>Preparation:</p>
                        <ul>
                          <li>
                            Clean the goat spleen thoroughly and chop it into
                            bite-sized pieces.
                          </li>
                          <li>
                            Prepare the onions, tomatoes, garlic, ginger, and
                            green chilies.
                          </li>
                        </ul>
                        <p style={{ fontWeight: 600 }}>Cooking the Soup:</p>
                        <ul>
                          <li>
                            Heat oil or ghee in a large pot over medium heat.
                            Add the cumin seeds, bay leaf, cinnamon stick, black
                            peppercorns, and cloves. Sauté until fragrant.
                          </li>
                          <li>
                            Add the chopped onions and sauté until they turn
                            golden brown.
                          </li>
                          <li>
                            Add the minced garlic, ginger, and green chilies.
                            Sauté for another minute.
                          </li>
                          <li>
                            Add the chopped tomatoes and cook until they soften
                            and the oil starts to separate.
                          </li>
                          <li>
                            Add the turmeric powder, coriander powder, red chili
                            powder, and salt. Cook for 2-3 minutes to allow the
                            spices to blend.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/p/DA7u9pLzUbo/?igsh=MWtkYml2cHNwbGd2ag=="
                    >
                      <img
                        className="img-fluid briyani-img"
                        src="/assets/rec5.png"
                        loading="lazy"
                        alt="img"
                      />
                    </a>
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>One-Pan Lemon Garlic Chicken</h2>
                    <p>
                      One-Pan Lemon Garlic Chicken is a tasty and easy dish that
                      perfectly captures the convenience of weeknight cooking.
                      This recipe simplifies meal preparation while ensuring a
                      burst of flavor that will please the whole family. With
                      just a few ingredients and minimal cleanup, it’s an ideal
                      choice for busy evenings.
                    </p>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h3>Ingredients:</h3>
                        <ul>
                          <li>4 boneless, skinless chicken breasts.</li>
                          <li>2 tablespoons olive oil.</li>
                          <li>4 cloves garlic, minced .</li>
                          <li>1 lemon (zested and juiced) .</li>
                          <li>1 cup chicken broth .</li>
                          <li>1 tablespoon butter .</li>
                          <li>Fresh parsley, chopped (for garnish) .</li>
                          <li>Salt and pepper to taste.</li>
                        </ul>
                        <h3>Instructions:</h3>
                        <ul>
                          <li>
                            Prep the Chicken: Season the chicken breasts with
                            salt, pepper, and half of the lemon zest.
                          </li>
                          <li>
                            Sear the Chicken: Heat olive oil in a large skillet
                            over medium heat. Add the chicken breasts and cook
                            for about 5-7 minutes on each side, until golden
                            brown. Remove from the pan and set aside.
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>
                            Make the Sauce: In the same skillet, lower the heat
                            and add the minced garlic. Sauté for about 1 minute
                            until fragrant. Pour in the chicken broth, lemon
                            juice, and remaining lemon zest. Stir and let it
                            simmer for 2-3 minutes.
                          </li>
                          <li>
                            Finish the Dish: Return the chicken to the skillet.
                            Add the butter to the sauce and cook the chicken in
                            the sauce for another 5-7 minutes, or until fully
                            cooked through (internal temperature of 165°F/75°C).
                          </li>
                          <li>
                            Serve: Garnish with fresh parsley and serve hot with
                            your choice of side, such as rice, roasted
                            vegetables, or potatoes.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <a
                      href="https://www.instagram.com/p/DA7vQ1jTRWU/?igsh=cGRkejZpY21seXcx"
                      target="_blank"
                    >
                      <img
                        className="img-fluid briyani-img"
                        src="/assets/rec6.png"
                        loading="lazy"
                        alt="img"
                      />
                    </a>
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Quick Chicken Stir-Fry</h2>
                    <p>
                      Quick chicken stir-fry is a fantastic option for busy
                      weeknights, combining speed, flavor, and nutrition in one
                      dish. With just a few ingredients and minimal preparation
                      time, you can create a satisfying meal that the whole
                      family will love.
                    </p>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h3>Ingredients:</h3>
                        <ul>
                          <li>
                            2 boneless, skinless chicken breasts (thinly sliced)
                            .
                          </li>
                          <li>2 tablespoons vegetable oil .</li>
                          <li>1 bell pepper (sliced) .</li>
                          <li>1 small onion (sliced) .</li>
                          <li>1 cup broccoli florets .</li>
                          <li>2 garlic cloves (minced) .</li>
                          <li>1 tablespoon soy sauce .</li>
                          <li>1 tablespoon oyster sauce .</li>
                          <li>1 tablespoon hoisin sauce. </li>
                          <li>1 tablespoon sesame oil (optional). </li>
                          <li>
                            1 teaspoon cornstarch (optional, for thickening).{" "}
                          </li>
                          <li>Salt and pepper to taste. </li>
                          <li>Cooked rice or noodles (for serving). </li>
                        </ul>
                        <h3>Instructions:</h3>
                        <ul>
                          <li>
                            Prep the Chicken: Season the sliced chicken breasts
                            with a pinch of salt and pepper.
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>
                            Cook the Vegetables: In the same pan, add the
                            remaining tablespoon of oil. Add the sliced bell
                            pepper, onion, broccoli florets, and minced garlic.
                            Stir-fry for 3-4 minutes until the veggies are
                            tender but still crisp.
                          </li>
                          <li>
                            Make the Sauce: In a small bowl, mix the soy sauce,
                            oyster sauce, hoisin sauce, and sesame oil. For a
                            thicker sauce, mix in 1 teaspoon of cornstarch with
                            a tablespoon of water.
                          </li>
                          <li>
                            Combine and Serve: Add the cooked chicken back into
                            the pan with the vegetables. Pour the sauce over the
                            chicken and veggies and stir until everything is
                            evenly coated and the sauce thickens (about 1-2
                            minutes).
                          </li>
                          <li>Serve: Serve hot over cooked rice or noodles.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <a
                      href="https://www.instagram.com/p/DA7vebyTngd/?igsh=dXJ2NDkwNXd5b2d0"
                      target="_blank"
                    >
                      <img
                        className="img-fluid briyani-img"
                        src="/assets/rec7.png"
                        loading="lazy"
                        alt="img"
                      />
                    </a>
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>5-Ingredient Chicken Quesadillas Recipe</h2>
                    <p>
                      When it comes to quick and satisfying meals, 5-Ingredient
                      Chicken Quesadillas stand out as a perfect choice for busy
                      weeknights. With just a handful of ingredients, you can
                      whip up a delicious dish that the whole family will love.
                    </p>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h3>Ingredients:</h3>
                        <ul>
                          <li>2 cups cooked chicken (shredded) .</li>
                          <li>
                            1 ½ cups shredded cheese (cheddar, Monterey Jack, or
                            a mix) .
                          </li>
                          <li>4 large flour tortillas .</li>
                          <li>
                            ½ cup salsa (your choice of mild, medium, or hot) .
                          </li>
                          <li>2 tablespoons butter or oil (for cooking) .</li>
                        </ul>
                        <h3>Instructions:</h3>
                        <ul>
                          <li>
                            Prepare the Filling: In a bowl, mix the shredded
                            chicken with salsa.
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>
                            Assemble the Quesadillas: Lay out the tortillas. On
                            half of each tortilla, sprinkle some shredded
                            cheese, add the chicken and salsa mixture, then
                            sprinkle more cheese on top. Fold the tortillas in
                            half to cover the filling.
                          </li>
                          <li>
                            Cook the Quesadillas: Heat 1 tablespoon of butter or
                            oil in a large skillet over medium heat. Place the
                            folded quesadillas in the skillet (you may need to
                            do this in batches). Cook for about 2-3 minutes on
                            each side, until the tortillas are golden and the
                            cheese is melted.
                          </li>
                          <li>
                            Serve: Cut into wedges and serve with your favorite
                            sides like guacamole, sour cream, or extra salsa.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <a
                      href="https://www.instagram.com/p/DA7vuUbTtYL/?igsh=MXQ3Z3VqbzhqMGZ1OA=="
                      target="_blank"
                    >
                      <img
                        className="img-fluid briyani-img"
                        src="/assets/rec8.png"
                        loading="lazy"
                        alt="img"
                      />
                    </a>
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Instant Pot Chicken and Rice</h2>
                    <p>
                      Cooking chicken in an Instant Pot is a game-changer for
                      busy weeknights, especially when paired with rice for a
                      complete meal. This method not only saves time but also
                      locks in flavors, making your chicken tender and juicy.
                    </p>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h3>Ingredients:</h3>
                        <ul>
                          <li>
                            4 boneless, skinless chicken breasts (or thighs) .
                          </li>
                          <li>1 cup long-grain white rice .</li>
                          <li>1 ½ cups chicken broth .</li>
                          <li>1 onion (chopped) .</li>
                          <li>2 garlic cloves (minced) .</li>
                          <li>1 teaspoon paprika. </li>
                          <li>1 teaspoon salt. </li>
                          <li>½ teaspoon black pepper. </li>
                          <li>1 tablespoon olive oil. </li>
                          <li>Fresh parsley (for garnish). </li>
                        </ul>
                        <h3>Instructions:</h3>
                        <ul>
                          <li>
                            Sauté the Chicken: Set your Instant Pot to "Sauté"
                            mode. Heat olive oil, and brown the chicken on both
                            sides for 2-3 minutes. Remove and set aside.
                          </li>
                          <li>
                            Sauté Onion & Garlic: Add chopped onion and garlic
                            to the pot, cooking until softened.
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>
                            Add Ingredients: Stir in rice, chicken broth,
                            paprika, salt, and pepper. Place the browned chicken
                            on top of the rice mixture.
                          </li>
                          <li>
                            Pressure Cook: Close the lid, set the valve to
                            "Sealing," and cook on high pressure for 10 minutes.
                            Allow a natural release for 10 minutes, then quick
                            release the remaining pressure.
                          </li>
                          <li>
                            Serve: Fluff the rice, garnish with fresh parsley,
                            and serve.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <a
                      href="https://www.instagram.com/p/DA7v-dLzj6Z/?igsh=MTV5ODJhNXJidHB2eg=="
                      target="_blank"
                    >
                      <img
                        className="img-fluid briyani-img"
                        src="/assets/rec9.png"
                        loading="lazy"
                        alt="img"
                      />
                    </a>
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Grilled Chicken Caprese</h2>
                    <p>
                      Grilled Chicken Caprese is a delightful dish that combines
                      the juicy flavors of grilled chicken with the fresh,
                      vibrant ingredients of a classic Caprese salad.
                    </p>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h3>Ingredients:</h3>
                        <ul>
                          <li>
                            4 boneless, skinless chicken breasts (or thighs) .
                          </li>
                          <li>1 tablespoon olive oil</li>
                          <li>Salt and pepper, to taste</li>
                          <li>4 slices fresh mozzarella cheese</li>
                          <li>2 tomatoes, sliced</li>
                          <li>Fresh basil leaves</li>
                          <li>2 tablespoons balsamic glaze</li>
                        </ul>
                        <h3>Instructions:</h3>
                        <ul>
                          <li>
                            Prep the Chicken: Brush chicken breasts with olive
                            oil and season with salt and pepper.
                          </li>
                          <li>
                            Grill the Chicken: Preheat the grill to medium-high
                            heat. Grill chicken for 6-7 minutes per side or
                            until cooked through (internal temperature of
                            165°F/75°C).
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>
                            Top with Mozzarella: In the last 2 minutes of
                            grilling, place a slice of mozzarella cheese on each
                            chicken breast to melt.
                          </li>
                          <li>
                            Assemble Caprese: Remove chicken from the grill, top
                            with fresh tomato slices and basil leaves.
                          </li>
                          <li>
                            Drizzle and Serve: Drizzle with balsamic glaze and
                            serve immediately.
                          </li>
                          <li>
                            A light and fresh dish with classic Caprese flavors!
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <a
                      href="https://www.instagram.com/p/DA7wJIjTD9l/?igsh=MXF3azF4N3E1YjlxZg=="
                      target="_blank"
                    >
                      <img
                        className="img-fluid briyani-img"
                        src="/assets/rec10.png"
                        loading="lazy"
                        alt="img"
                      />
                    </a>
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Creamy Chicken Pasta</h2>
                    <p>
                      Creamy Chicken Pasta is a delightful dish that combines
                      tender chicken with a rich, creamy sauce, making it a
                      perfect choice for busy weeknights. This recipe not only
                      highlights the versatility of chicken but also showcases
                      how easily it can be transformed into a comforting meal
                      that the whole family will love.
                    </p>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h3>Ingredients:</h3>
                        <ul>
                          <li>
                            2 boneless, skinless chicken breasts (sliced) .
                          </li>
                          <li>2 tablespoons olive oil.</li>
                          <li>3 garlic cloves (minced).</li>
                          <li>1 cup heavy cream.</li>
                          <li>1 cup chicken broth.</li>
                          <li>1 cup Parmesan cheese (grated).</li>
                          <li>8 oz pasta (penne or fettuccine).</li>
                          <li>Salt and pepper to taste. </li>
                          <li>Fresh parsley for garnish. </li>
                        </ul>
                        <h3>Instructions:</h3>
                        <ul>
                          <li>
                            Cook the Pasta: Boil pasta according to package
                            instructions. Drain and set aside.
                          </li>
                          <li>
                            Cook the Chicken: In a large skillet, heat olive oil
                            over medium heat. Season the chicken with salt and
                            pepper, then sauté until golden and fully cooked
                            (about 6-8 minutes). Remove and set aside.
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>
                            Make the Sauce: In the same skillet, add garlic and
                            sauté until fragrant (about 1 minute). Pour in
                            chicken broth and heavy cream. Stir and let it
                            simmer for 2-3 minutes.
                          </li>
                          <li>
                            Add Cheese & Chicken: Stir in Parmesan cheese until
                            melted and smooth. Return the cooked chicken to the
                            pan and combine with the sauce.
                          </li>
                          <li>
                            Toss with Pasta: Add the cooked pasta to the
                            skillet, tossing it with the creamy chicken sauce
                            until everything is well-coated.
                          </li>
                          <li>
                            Serve: Garnish with fresh parsley and serve hot.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <a
                      href="https://www.instagram.com/p/DA7wWvxzheW/?igsh=MzFmMnhzZnZlamF6"
                      target="_blank"
                    >
                      <img
                        className="img-fluid briyani-img"
                        src="/assets/rec11.png"
                        loading="lazy"
                        alt="img"
                      />
                    </a>
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>15-Minute Chicken Noodle Soup</h2>
                    <p>
                      When time is of the essence, a quick and satisfying meal
                      can be a lifesaver. Enter the 15-Minute Chicken Noodle
                      Soup, a delightful dish that combines the comforting
                      flavors of chicken with the heartiness of noodles, all
                      prepared in a fraction of the time it typically takes to
                      make soup.
                    </p>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h3>Ingredients:</h3>
                        <ul>
                          <li>2 cups cooked chicken (shredded or diced) .</li>
                          <li>4 cups chicken broth .</li>
                          <li>1 cup egg noodles (or any pasta) .</li>
                          <li>
                            1 cup mixed vegetables (frozen or fresh, like
                            carrots and peas) .
                          </li>
                          <li>2 garlic cloves (minced) .</li>
                          <li>
                            1 teaspoon dried thyme (or Italian seasoning) .
                          </li>
                          <li>Salt and pepper to taste .</li>
                          <li>Fresh parsley for garnish. </li>
                        </ul>
                        <h3>Instructions:</h3>
                        <ul>
                          <li>
                            Boil the Broth: In a large pot, bring the chicken
                            broth to a boil over medium-high heat.
                          </li>
                          <li>
                            Add Noodles & Vegetables: Stir in the egg noodles
                            and mixed vegetables. Cook for about 5 minutes until
                            the noodles are tender.
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>
                            Add Chicken & Seasoning: Add the cooked chicken,
                            minced garlic, thyme, salt, and pepper. Stir well
                            and heat through for another 2-3 minutes.
                          </li>
                          <li>
                            Serve: Ladle the soup into bowls, garnish with fresh
                            parsley if desired, and enjoy!
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <a
                      href="https://www.instagram.com/p/DA7wmPfTt7a/?igsh=dG8ycjg0ZWM1NG5o"
                      target="_blank"
                    >
                      <img
                        className="img-fluid briyani-img"
                        src="/assets/rec12.png"
                        loading="lazy"
                        alt="img"
                      />
                    </a>
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Baked Parmesan Chicken Tenders</h2>
                    <p>
                      Baked Parmesan Chicken Tenders are a delightful and
                      easy-to-make dish that brings together the rich flavors of
                      chicken and cheese, making it a perfect choice for busy
                      weeknights. This recipe not only satisfies your taste buds
                      but also fits seamlessly into a quick dinner routine,
                      taking less than 30 minutes from prep to plate.
                    </p>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h3>Ingredients:</h3>
                        <ul>
                          <li>1 lb chicken tenders</li>
                          <li>1 cup grated Parmesan cheese</li>
                          <li>1 cup breadcrumbs (panko or regular)</li>
                          <li>1 teaspoon garlic powder</li>
                          <li>1 teaspoon Italian seasoning</li>
                          <li>Salt and pepper to taste</li>
                          <li>2 eggs (beaten)</li>
                          <li>Cooking spray or olive oil</li>
                        </ul>
                        <h3>Instructions:</h3>
                        <ul>
                          <li>
                            Preheat Oven: Preheat your oven to 400°F (200°C).
                            Line a baking sheet with parchment paper or lightly
                            grease it with cooking spray.
                          </li>
                          <li>
                            Prepare Breading Mixture: In a shallow bowl, mix
                            together the grated Parmesan cheese, breadcrumbs,
                            garlic powder, Italian seasoning, salt, and pepper.
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>
                            Coat the Chicken: Dip each chicken tender into the
                            beaten eggs, allowing excess to drip off. Then, coat
                            the chicken in the Parmesan-breadcrumb mixture,
                            pressing lightly to adhere.
                          </li>
                          <li>
                            Arrange on Baking Sheet: Place the coated chicken
                            tenders on the prepared baking sheet. Lightly spray
                            the tops with cooking spray or drizzle with olive
                            oil.
                          </li>
                          <li>
                            Bake: Bake for 15-20 minutes, or until the chicken
                            is cooked through and the coating is golden brown
                            (internal temperature should reach 165°F/75°C).
                          </li>
                          <li>
                            Serve: Serve hot with your favorite dipping sauce,
                            such as marinara or ranch.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <a
                      href="https://www.instagram.com/p/DA7w6hmTMuI/?igsh=MW9ncDNlN3ZtZDV4Yg=="
                      target="_blank"
                    >
                      <img
                        className="img-fluid briyani-img"
                        src="/assets/rec13.png"
                        loading="lazy"
                        alt="img"
                      />
                    </a>
                    <div className="play-icon">
                      <i className="fi fi-ss-meat playicon"></i>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="briyani">
                    <h2>Slow Cooker Salsa Chicken</h2>
                    <p>
                      Slow cooker recipes are a lifesaver for busy weeknights,
                      and Slow Cooker Salsa Chicken is a standout option that
                      combines convenience with flavor. This dish is not only
                      easy to prepare but also allows for a variety of
                      adaptations based on your taste preferences.
                    </p>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <h3>Ingredients:</h3>
                        <ul>
                          <li>4 boneless, skinless chicken breasts</li>
                          <li>
                            1 cup salsa (your choice of mild, medium, or hot)
                          </li>
                          <li>
                            1 can (15 oz) black beans (drained and rinsed)
                          </li>
                          <li>1 can (15 oz) corn (drained)</li>
                          <li>1 teaspoon cumin</li>
                          <li>1 teaspoon chili powder</li>
                          <li>Salt and pepper to taste</li>
                          <li>
                            Optional toppings: shredded cheese, avocado, sour
                            cream, fresh cilantro
                          </li>
                        </ul>
                        <h3>Instructions:</h3>
                        <ul>
                          <li>
                            Prepare the Chicken: Place the chicken breasts in
                            the slow cooker. Season with salt, pepper, cumin,
                            and chili powder.
                          </li>
                          <li>
                            Add Salsa and Beans: Pour the salsa over the
                            chicken, then add the black beans and corn on top.
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul>
                          <li>
                            Cook: Cover and cook on low for 6-7 hours or high
                            for 3-4 hours until the chicken is cooked through
                            and tender.
                          </li>
                          <li>
                            Shred the Chicken: Once cooked, shred the chicken in
                            the slow cooker using two forks. Mix it with the
                            salsa and bean mixture.
                          </li>
                          <li>
                            Serve: Serve the salsa chicken over rice, in tacos,
                            or in a bowl. Top with cheese, avocado, sour cream,
                            and cilantro if desired.
                          </li>
                          <li>
                            A simple, flavorful dish that’s perfect for busy
                            days!
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Recipe;
