import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../Home/Header";
import { Button } from "@mui/material";
import Footer from "../Footer/Footer";

const data = [
  {
    slug: "The-Incredible-Benefits-of-Goat-Spleen-During-Pregnancy",
    date: "Oct 2024",
    title: "The Incredible Benefits of Goat Spleen During Pregnancy",
    image: "/assets/preg.jpeg",
    text: "Ensuring that pregnant women maintain both balanced nutrition and multiple essential nutrients remains fundamental during their pregnancy. Nutrient-dense foods such as goat spleen stand apart from typical food choices during pregnancy alongside fruits, vegetables and grain",
  },
  {
    slug: "chicken-for-briyani",
    date: "Oct 2024",

    title: "How to Cook Chicken for Biryani: The Perfect Guide",
    image: "/assets/bir.jpeg",
    text: "The success of a Chicken for biryani meal hinges on obtaining the right pieces of chicken. When you cook chicken for biryani correctly you will receive deliciously tasty and tender pieces in every mouthful.",
  },
  {
    slug: "chicken-wings",
    date: "Nov 2024",

    title: "The Ultimate Chicken Wings Recipe: Crispy, Juicy & Irresistible",
    image: "/assets/wings.jpeg",
    text: "Chicken wings rank among the most loved appetizers that food lovers enjoy. Chicken wings stand out because their perfectly made exterior enhances the tender interior to create a beloved snack and party appetizer.",
  },
  {
    slug: "Calories-in-Chicken-Lollipo",
    date: "Dec 2024",

    title: "Calories in Chicken Lollipop: Nutritional Facts & Health Benefits",
    image: "/assets/lol.jpeg",
    text: "People enjoy Chicken lollipop as a beloved appetizer because its signature qualities combine delectable juicy meat with deliciously crispy crust. Many individuals enjoy this dish yet they want to know both the calorie content along with whether this food aligns with a well-balanced diet plan.",
  },
  {
    slug: "boneless-chicken",
    date: "Dec 2024",

    title: "Buy Fresh Boneless Chicken Online,Quality & Taste",
    image: "/assets/bon.jpeg",
    text: "People can now revolutionize their cooking experience by purchasing boneless chicken through online stores in the fast-paced modern world. The online purchase of fresh chicken grants users the opportunity to obtain high-quality tender meat which will be promptly delivered to their home for any domestic cooking situation.",
  },
  {
    slug: "online-meat-delivery",
    date: "Jan 2025",

    title: "Online Meat Delivery At Your Doorstep",
    image: "/assets/del.webp",
    text: "Online meat delivery represents a major change within the food industry as consumers switch to internet-based meat purchases. Fresh high-quality meat delivery services now provide premium products to customers through doorstep deliveries from their convenient home locations",
  },
];

const CardGrid: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header />
      <Container className="card-div my-5">
        <Row className="g-5 justify-content-center">
          {data.map((item) => (
            <Col
              key={item.slug}
              md={4}
              className="d-flex justify-content-center"
            >
              <Card className="mt-5">
                <Card.Img
                  variant="top"
                  src={item.image}
                  loading="lazy"
                  style={{
                    height: "220px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />

                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text className="truncate-text">{item.text}</Card.Text>
                  <Link to={`/blogs/${item.slug}`}>
                    <Button className="see-btn" style={{ color: "white" }}>
                      {" "}
                      Read More
                    </Button>
                  </Link>
                  <p className="mt-2 mb-0">{item.date}</p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default CardGrid;
