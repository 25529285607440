import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Rating, Tab, Tabs } from "@mui/material";
import { message, Select } from "antd";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import { getSlugProduct } from "../Providers/Api_Calls";
import "./Orders.css";
import { API_URL } from "../Config";
import Allcarditems from "../Allcarditems";
import { Helmet } from "react-helmet";
import axios from "axios";

interface LineItem {
  product: string;
  quantity: number | null;
}

interface OrderData {
  orderId: number;
  lineItems: LineItem[];
  type: string;
  deliverySlot: string;
  address: string;
  paymentMethod: string;
  paymentStatus: string;
  status: string;
  deliveryInstructions: string;
  isCancelled: boolean;
  cancellationReason: string | null;
  refundStatus: string;
  paymentAmount: number;
  productName: string;
  discountInPercent: number;
  originalPrice: number;
  originalQuantity: number;
}

const ProductView = () => {
  const [value, setValue] = useState("Productdescription");
  const [productData, setProductData] = useState<any>(null);
  const nutritionalList = productData
    ? productData.nutritionalInfo
        .split("\r\n")
        .filter((info: string) => info.trim() !== "")
    : ["No nutritional information available"];

  const [weight, setWeight] = useState<number | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const { slug } = useParams<{ slug: string }>();
  const [recommendedprod, setRecommendedprod] = useState<any[]>([]);
  const [metaDatas, setMetaDatas] = useState({
    metaHeading: "",
    metaContent: "",
    metaKeys: "",
  });
  const [id, setId] = useState("");
  const [data, setData] = useState<OrderData>({
    orderId: 0,
    lineItems: [
      {
        product: id || "",
        quantity: weight,
      },
    ],
    type: "",
    deliverySlot: "",
    address: "",
    paymentMethod: "",
    paymentStatus: "",
    status: "",
    deliveryInstructions: "",
    isCancelled: false,
    cancellationReason: null,
    refundStatus: "",
    paymentAmount: Number(price),
    productName: productData ? productData.name : "",
    discountInPercent: productData?.discountInPercent || 0,
    originalPrice: productData?.price || 0,
    originalQuantity: productData?.minWeightInGrams || 0,
  });

  const getAllProduct = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/product?category=${
          slug?.includes("chicken") ? "chicken" : "mutton"
        }&page=${1}&limit=4&isDeleted=false`
      );
      setRecommendedprod(response.data.products);
    } catch (error) {
      console.error(error);
    }
  };

  const [originalPrice, setOriginalPrice] = useState<number | null>(null);
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null);

  const handleProduct = async () => {
    try {
      const fetchProduct = await getSlugProduct(
        ApiEndPoints("getbyslug"),
        slug || ""
      );
      setId(fetchProduct.product._id);
      setMetaDatas({
        metaHeading: fetchProduct.product.metaHeading,
        metaContent: fetchProduct.product.metaContent,
        metaKeys: fetchProduct.product.metaKeys,
      });

      setProductData(fetchProduct.product);
      setWeight(fetchProduct.product.minWeightInGrams);
      setPrice(fetchProduct.product.price);

      // Calculate and set the original and discounted prices
      const originalPrice = fetchProduct.product.price;
      const discountInPercent = fetchProduct.product.discountInPercent || 0;
      const discountedPrice =
        originalPrice - (originalPrice * discountInPercent) / 100;

      setOriginalPrice(originalPrice);
      setDiscountedPrice(discountedPrice);

      getAllProduct();
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const handleIncrement = () => {
    if (weight !== null && price !== null && productData) {
      const unitPrice = productData.price / productData.minWeightInGrams;

      setWeight((prevWeight) => {
        if (prevWeight !== null && prevWeight + 100 > 1500) {
          return prevWeight;
        }
        return prevWeight !== null ? prevWeight + 100 : 100;
      });

      setPrice((prevPrice) => {
        if (weight !== null && weight + 100 > 1500) {
          return prevPrice;
        }
        return prevPrice !== null
          ? prevPrice + unitPrice * 100
          : unitPrice * 250;
      });
    }
  };

  const handleDecrement = () => {
    if (
      weight !== null &&
      weight > (productData?.minWeightInGrams || 0) &&
      productData
    ) {
      const unitPrice = productData.price / productData.minWeightInGrams;
      setWeight((prevWeight) => (prevWeight !== null ? prevWeight - 100 : 0));
      setPrice((prevPrice) =>
        prevPrice !== null ? prevPrice - unitPrice * 100 : 0
      );
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handledropdown = (value: string) => {
    setData((prevData) => ({ ...prevData, type: value }));
  };

  useEffect(() => {
    handleProduct();
  }, [id]);

  useEffect(() => {
    if (productData) {
      const totalPrice =
        ((weight ?? 0) / productData.minWeightInGrams) * productData.price;
      const discountAmount = totalPrice * (productData.discountInPercent / 100);
      const finalPrice = totalPrice - discountAmount;
      setData((prevData) => ({
        ...prevData,
        lineItems: [
          {
            product: id || "",
            quantity: weight || null,
          },
        ],
        productName: productData.name || "",
        paymentAmount: finalPrice || 0,
        discountInPercent: productData.discountInPercent || 0,
        originalPrice: productData.price || 0,
        originalQuantity: productData.minWeightInGrams || 0,
      }));
    }
  }, [weight, price, productData]);

  const handlepostOrder = () => {
    // Retrieve the cart from localStorage or initialize it as an empty array
    let cart = JSON.parse(localStorage.getItem("cart") || "[]");

    cart.push({
      orderId: data.orderId,
      lineItems: [
        {
          product: data.lineItems[0].product,
          quantity: data.lineItems[0].quantity,
        },
      ],
      type: data.type ? data.type : "normal",
      deliverySlot: data.deliverySlot,
      address: data.address,
      paymentMethod: data.paymentMethod,
      paymentStatus: data.paymentStatus,
      status: data.status,
      deliveryInstructions: data.deliveryInstructions,
      isCancelled: data.isCancelled,
      cancellationReason: data.cancellationReason,
      refundStatus: data.refundStatus,
      paymentAmount: data.paymentAmount,
      productName: data.productName,
      discountInPercent: data.discountInPercent,
      originalPrice: data.originalPrice,
      originalQuantity: data.originalQuantity,
    });

    // Save the updated cart to localStorage
    localStorage.setItem("cart", JSON.stringify(cart));
    message.success("Items added to cart!");
    window.dispatchEvent(new Event("storage"));
  };

  if (!productData) {
    return <div>Loading...</div>;
  }

  const handleBuyProd = () => {
    handlepostOrder();
    sessionStorage.setItem("openCart", "true");
  };

  const totalPrice =
    ((weight ?? 0) / productData.minWeightInGrams) * productData.price;

  const discountAmount = totalPrice * (productData.discountInPercent / 100);
  const finalPrice = totalPrice - discountAmount;

  return (
    <>
      <Helmet>
        <title>{metaDatas.metaHeading}</title>
        <meta name="description" content={metaDatas.metaContent} />
        <meta name="keywords" content={metaDatas.metaKeys} />
      </Helmet>
      <section className="py-4">
        <Container>
          <p className="nav-all pb-3">
            <Link to="/">Home</Link> {">"}{" "}
            <Link to={`/${productData.category}`}>
              {productData.category.charAt(0).toUpperCase() +
                productData.category.slice(1)}
            </Link>{" "}
            {">"} <span>Product Details</span>
          </p>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="fresh">
                <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                  <img
                    className="img-fluid"
                    src={
                      `${API_URL}/images/${productData.image}` ||
                      "/assets/product.png"
                    }
                    style={{ width: "400px" }}
                    loading="lazy"
                    alt={productData.name}
                  />
                </div>
                <div className="small-imgs">
                  <div>
                    <img
                      className="img-fluid fresh-img"
                      src="/assets/fresh.png"
                      style={{ width: "80px" }}
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid fresh-img"
                      src="/assets/premium_certified_quality_stamp 1.png"
                      style={{ width: "80px" }}
                    />
                  </div>
                  <div>
                    <img
                      className="img-fluid fresh-img"
                      src="/assets/same.png"
                      style={{ width: "80px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div>
                <div>
                  <h2>{productData.name}</h2>
                  <div className="d-flex justify-content-start align-items-center">
                    <Rating
                      name="half-rating-read"
                      defaultValue={productData.rating || 2.5}
                      precision={0.5}
                      readOnly
                    />
                    <span> {productData.rating || 2.5}.0</span>
                  </div>
                  <div className="d-flex mt-3">
                    <div className="increDecQ me-2">
                      <span className="minusQ" onClick={handleDecrement}>
                        -
                      </span>
                      <span className="QinputQuantity">
                        {weight !== null ? `${weight} gm` : ""}
                      </span>
                      <span className="plusQ" onClick={handleIncrement}>
                        +
                      </span>
                    </div>
                    <div className="mx-2">
                      <Select
                        placeholder="Clean Type"
                        style={{ width: 140 }}
                        onChange={handledropdown}
                        options={[
                          { value: "full clean", label: "Full Clean" },
                          { value: "semi clean", label: "Semi Clean" },
                          { value: "normal", label: "No Clean" },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="d-flex py-3">
                    <div className="d-flex  flex-wrap justify-content-start align-items-center">
                      {productData.discountInPercent === 0 ? (
                        <span className="discount-amount2">
                          ₹ {originalPrice?.toFixed()}
                        </span>
                      ) : (
                        <span className="discount-amount2">
                          ₹ {finalPrice?.toFixed()}
                        </span>
                      )}
                      {productData.discountInPercent === 0 ? (
                        ""
                      ) : (
                        <span className="amount-View discount-strike">
                          ₹ {totalPrice?.toFixed()}
                        </span>
                      )}
                      <span className="discount">
                        {productData.discountInPercent === 0
                          ? ""
                          : productData.discountInPercent + "% offer"}
                      </span>
                    </div>

                    <div className="d-none d-sm-block">
                      <Button
                        variant="contained"
                        className="viewadd-cart"
                        onClick={handlepostOrder}
                      >
                        Add To Cart
                      </Button>
                    </div>
                    <div className="d-none d-sm-block">
                      <Button
                        variant="contained"
                        className="viewadd-cart"
                        onClick={handleBuyProd}
                      >
                        Buy Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="d-flex" style={{ width: "100%" }}>
                  <div
                    className="d-block d-sm-none pb-3 mx-1"
                    style={{ width: "100%" }}
                  >
                    <Button
                      variant="contained"
                      className="viewadd-cart-mobile"
                      onClick={handlepostOrder}
                    >
                      Add To Cart
                    </Button>
                  </div>
                  <div
                    className="d-block d-sm-none pb-3 mx-1"
                    style={{ width: "100%" }}
                  >
                    <Button
                      variant="contained"
                      className="viewadd-cart-mobile"
                      onClick={handleBuyProd}
                    >
                      Buy Now
                    </Button>
                  </div>
                </div>
                <div className="d-none d-sm-block">
                  <Box sx={{ width: "100%" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="wrapped label tabs example"
                    >
                      <Tab
                        value="Productdescription"
                        label="Product description"
                      />
                      <Tab
                        value="DeliveryInformation"
                        label="Delivery Information"
                      />
                      <Tab
                        value="NutritionalValues"
                        label="Nutritional Values"
                      />
                    </Tabs>
                  </Box>
                  {value === "Productdescription" && (
                    <div className="py-3">
                      <p>
                        {productData.description || "No description available"}
                      </p>
                    </div>
                  )}
                  {value === "DeliveryInformation" && (
                    <div className="py-3">
                      <p>
                        {productData.deliveryInfo ||
                          "No delivery information available"}
                      </p>
                    </div>
                  )}
                  {value === "NutritionalValues" && (
                    <div className="py-3">
                      <p>
                        {productData.nutritionalInfo ||
                          "No nutritional information available"}
                      </p>
                    </div>
                  )}
                </div>
                <div className="d-block d-sm-none">
                  <h3>Product description</h3>
                  <p>{productData.description || "No description available"}</p>
                  <h3>Delivery Information</h3>
                  <p>
                    {productData.deliveryInfo ||
                      "No delivery information available"}
                  </p>
                  <h3>Nutritional Values</h3>
                  <ul>
                    {nutritionalList.map((val: any) => (
                      <li>{val}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section>
        <Container>
          <h2 style={{ fontWeight: 700, fontSize: "24px" }}>
            Recommended Products:
          </h2>
          <div className="row py-3">
            {recommendedprod.length ? (
              recommendedprod.map((product) => (
                <Allcarditems key={product._id} product={product} />
              ))
            ) : (
              <h2 className="text-center">No items found !</h2>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default ProductView;
