import React from "react";
import { Container } from "react-bootstrap";

const Contact = () => {
  return (
    <>
      <section className="py-3">
        <Container>
          <h2>Contact Us:</h2>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
              <div
                className="p-2"
                style={{
                  border: "1px solid black",
                  borderRadius: "10px",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <img
                  src="/assets/Mask group (51).png"
                  style={{ width: "50px" }}
                  loading="lazy"
                  alt="img"
                />
                <h5 className="py-2">Call us</h5>
                <p>
                  Feel free to give us a call, and we’ll be happy to provide you
                  with the support you need.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
              <div
                className="p-2"
                style={{
                  border: "1px solid black",
                  borderRadius: "10px",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <img
                  src="/assets/Mask group (52).png"
                  style={{ width: "50px" }}
                  loading="lazy"
                  alt="img"
                />
                <h5 className="py-2">Email us</h5>
                <p>
                  Whether you need assistance, have questions about our fresh
                  meat products.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
              <div
                className="p-2"
                style={{
                  border: "1px solid black",
                  borderRadius: "10px",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <img
                  src="/assets/Mask group (53).png"
                  style={{ width: "50px" }}
                  loading="lazy"
                  alt="img"
                />
                <h5 className="py-2">Visit Us</h5>
                <p>
                  We’d love to see you at Meat Market! stop by our store to
                  explore our fresh chicken and mutton.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Contact;
