export const ApiEndPoints = (name: any) => {
  var list: any = {
    allProduct: "api/product",
    signUp: "api/user/auth/signup",
    login: "api/user/auth/login",
    forgetPassword: "api/user/auth/forgotPassword",
    bulkOrders: "api/bulkOrder",
    requestFranchise: "/api/request",
    getProduct: "/api/product",
    postOrder: "api/order",
    getMe: "api/user/getMe",
    getallOrders: "api/order",
    patchAddress: "api/user",
    myOrders: "api/order",
    updateuser: "api/user",
    cancelproduct: "api/order",
    getRating: "api/review",
    transfer: "api/transaction",
    getBanner: "api/heroPage",
    getbyslug: "api/product/slug",
  };
  return list[name];
};
